import { ContainerModuleLoader, multi, withDependencies } from '@wix/thunderbolt-ioc'
import {
	AppPropsSym,
	DomReadySymbol,
	IAppWillMountHandler,
	IAppPropsImpl,
	IRenderer,
	LifeCycle,
	RendererSymbol,
} from '@wix/thunderbolt-symbols'
import { AppProps } from 'feature-react-renderer'
import { NavigationInfoSym, Thunderbolt } from './types'
import { IThunderbolt } from './IThunderbolt'
import { createDomReadyPromise, WaitForDomReady } from './DomReady'
import { NavigationInfo } from './NavigationInfo'
import { taskify } from '@wix/thunderbolt-commons'

const ThunderboltImpl = (
	appProps: IAppPropsImpl,
	initializables: Array<IAppWillMountHandler>,
	renderer: IRenderer<AppProps, any>
): IThunderbolt => {
	const getAppProps = () => appProps.getAppProps() as AppProps
	return {
		ready: async () => {
			const initThings = initializables.map((initializable) => taskify(() => initializable.appWillMount()))
			await Promise.all([appProps.resolveAppProps(), ...initThings])
		},
		render: () => {
			return renderer.render(getAppProps())
		},
		getAppProps,
	}
}

const thunderboltImpl = withDependencies(
	[AppPropsSym, multi(LifeCycle.AppWillMountHandler), RendererSymbol],
	ThunderboltImpl
)

export const site: ContainerModuleLoader = (bind) => {
	bind(Thunderbolt).to(thunderboltImpl)
	if (process.env.browser) {
		bind(DomReadySymbol).toConstantValue(createDomReadyPromise())
		bind(LifeCycle.AppWillMountHandler).to(WaitForDomReady)
	}
	const navigationInfo = new NavigationInfo()
	bind(LifeCycle.PageWillMountHandler).toConstantValue(navigationInfo)
	bind(NavigationInfoSym).toConstantValue(navigationInfo)
}

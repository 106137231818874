import { withDependencies, ContainerModuleLoader, multi } from '@wix/thunderbolt-ioc'
import { AppPropsSym, IAppPropsImpl, AppPropsExtenderSym, IAppPropsExtender } from '@wix/thunderbolt-symbols'

const AppPropsImpl = (appPropsExtenders: Array<IAppPropsExtender>): IAppPropsImpl => {
	let resolvedAppPropsExtenders: Array<object>
	return {
		resolveAppProps: async () => {
			resolvedAppPropsExtenders = await Promise.all(
				appPropsExtenders.map((extender) => extender.extendAppProps())
			)
		},
		getAppProps: () =>
			resolvedAppPropsExtenders.reduce((acc, partialContext) => Object.assign(acc, partialContext), {}),
	}
}

export const site: ContainerModuleLoader = (bind) => {
	bind(AppPropsSym).to(withDependencies([multi(AppPropsExtenderSym)], AppPropsImpl))
}

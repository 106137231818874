import { ContainerModuleLoader, withDependencies } from '@wix/thunderbolt-ioc'
import { getStore } from './stores'
import { StructureAPI } from './structureApi'
import {
	AppStructure,
	PropsMap,
	CompActions,
	IStructureStore,
	IPropsStore,
	Props,
	ICompActionsStore,
	CompActionsSym,
	IStructureAPI,
	StructureAPI as StructureAPISym,
	IAppPropsExtender,
	AppPropsExtenderSym,
} from '@wix/thunderbolt-symbols'
import { Structure } from './symbols'

export { Structure }

const appPropsExtender = withDependencies(
	[StructureAPISym, Props],
	(structureAPI: IStructureAPI, propsStore: IPropsStore): IAppPropsExtender => {
		return {
			async extendAppProps() {
				await structureAPI.addShellStructure()
				return {
					structure: structureAPI,
					props: propsStore,
				}
			},
		}
	}
)

export const site: ContainerModuleLoader = (bind) => {
	const structure = getStore<AppStructure>()
	const props = getStore<PropsMap>()
	const compActions = getStore<CompActions>()

	// Serializable
	bind<IStructureStore>(Structure).toConstantValue(structure)
	bind<IPropsStore>(Props).toConstantValue(props)
	// Not Serializable
	bind<ICompActionsStore>(CompActionsSym).toConstantValue(compActions)

	bind<IStructureAPI>(StructureAPISym).to(StructureAPI)
	bind<IAppPropsExtender>(AppPropsExtenderSym).to(appPropsExtender)
}

import { Event, LoadStatus, OnTagManagerReady, LoadedScripts } from './types'
import { handleLoadingTagsEvent, handleTagLoadedEvent } from './handlers'

export const onTagManagerReady: OnTagManagerReady = () =>
	new Promise((resolve) => {
		let loadedScripts: LoadedScripts = {}

		const setLoadedScripts = (scripts = {}) => {
			if (Object.entries(scripts).length) {
				loadedScripts = scripts
				const scriptValues = Object.values(loadedScripts || {})
				const isLoaded = scriptValues.every((script: any) => script.loadStatus)
				if (isLoaded) {
					resolve(loadedScripts)
				}
			} else {
				resolve(loadedScripts)
			}
		}
		const setTagLoaded = (loadedScript = {}) => {
			const updatedScripts = { ...loadedScripts, ...loadedScript }
			setLoadedScripts(updatedScripts)
		}

		window.addEventListener(Event.LoadingTags, ((event: CustomEvent) =>
			handleLoadingTagsEvent(event, setLoadedScripts)) as EventListener)

		window.addEventListener(Event.TagLoaded, ((event: CustomEvent) =>
			handleTagLoadedEvent(event, setTagLoaded, LoadStatus.Success)) as EventListener)

		window.addEventListener(Event.TagLoadError, ((event: CustomEvent) =>
			handleTagLoadedEvent(event, setTagLoaded, LoadStatus.Error)) as EventListener)
	})

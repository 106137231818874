import { LoadedScripts } from '../tag-manager/types'
import { ReporterEvent, ReporterState, IReporterApi, ReporterSiteConfig } from '..'
import { getReporterProps } from './get-reporter-props'
import { WixBiSession, BusinessLogger } from '@wix/thunderbolt-symbols'
import { IFeatureState } from 'thunderbolt-feature-state'
import { setState } from '../utils'
import { onTagManagerReady } from '../tag-manager'

export async function init(
	reporterApi: IReporterApi,
	siteConfig: ReporterSiteConfig,
	wixBiSession: WixBiSession,
	businessLogger: BusinessLogger,
	featureState: IFeatureState<ReporterState>
) {
	const loadedScripts: LoadedScripts = await onTagManagerReady()

	const reporterProps = getReporterProps(siteConfig, wixBiSession)
	const { pendingEvents } = featureState.get()

	const api = await import('../api' /* webpackChunkName: "reporter-api" */)
	api.init(reporterProps, loadedScripts, businessLogger)

	setState(featureState, { isAdapterInitialized: true })
	pendingEvents.forEach((event: ReporterEvent) => reporterApi.trackEvent(event))
	setState(featureState, { pendingEvents: [] })
}

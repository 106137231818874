import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { CurrentRouteInfoSymbol, SiteFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { name } from './symbols'
import { ICurrentRouteInfo, IRoutingConfig, IRoutingLinkUtilsAPI, CandidateRouteInfo } from './types'

const RoutingLinkUtilsAPIFactory = (
	routingConfig: IRoutingConfig,
	currentRouteInfo: ICurrentRouteInfo
): IRoutingLinkUtilsAPI => {
	return {
		getLinkUtilsRoutingInfo() {
			const currentRootInfo = currentRouteInfo.getCurrentRouteInfo()
			return {
				mainPageId: routingConfig.mainPageId,
				routes: routingConfig.routes,
				pageId: currentRootInfo!.pageId,
				// TODO
				relativeUrl: (currentRootInfo as CandidateRouteInfo)!.relativeUrl,
			}
		},
	}
}
export const RoutingLinkUtilsAPI = withDependencies(
	[named(SiteFeatureConfigSymbol, name), CurrentRouteInfoSymbol],
	RoutingLinkUtilsAPIFactory
)

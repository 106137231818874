import { ContainerModuleLoader, withDependencies } from '@wix/thunderbolt-ioc'
import { ICompsLifeCycle, CompsLifeCycleSym, AppPropsExtenderSym, IAppPropsExtender } from '@wix/thunderbolt-symbols'
import _ from 'lodash'

type CompCallbacks = { [compId: string]: { callbacks: { [callbackName: string]: Function } } }

const CompsLifeCycle = (): ICompsLifeCycle => {
	const onCompRenderedCallbacks: CompCallbacks = {}
	const registerToCompLifeCycle: ICompsLifeCycle['registerToCompLifeCycle'] = (compIds, callbackName, callback) => {
		_.forEach(compIds, (compId) => {
			onCompRenderedCallbacks[compId] = onCompRenderedCallbacks[compId] || {}
			if (!onCompRenderedCallbacks[compId].callbacks) {
				onCompRenderedCallbacks[compId].callbacks = { [callbackName]: callback }
			} else {
				onCompRenderedCallbacks[compId].callbacks[callbackName] = callback
			}
		})
	}

	const notifyCompDidMount: ICompsLifeCycle['notifyCompDidMount'] = (compId, displayedId) => {
		if (onCompRenderedCallbacks[compId]) {
			_.forEach(onCompRenderedCallbacks[compId].callbacks, (cb: Function) => {
				cb(compId, displayedId, document.getElementById(displayedId))
			})
		}
	}

	const unregisterToCompLifeCycle: ICompsLifeCycle['unregisterToCompLifeCycle'] = (compIds, callbackName) => {
		_.forEach(compIds, (compId) => {
			onCompRenderedCallbacks[compId].callbacks = _.omit(onCompRenderedCallbacks[compId].callbacks, callbackName)
		})
	}

	return {
		registerToCompLifeCycle,
		unregisterToCompLifeCycle,
		notifyCompDidMount,
	}
}

const AppPropsExtender = (compsLifeCycle: ICompsLifeCycle): IAppPropsExtender => {
	return {
		extendAppProps: async () => ({ compsLifeCycle }),
	}
}

export const site: ContainerModuleLoader = (bind) => {
	bind(CompsLifeCycleSym).to(withDependencies([], CompsLifeCycle))
	bind(AppPropsExtenderSym).to(withDependencies([CompsLifeCycleSym], AppPropsExtender))
}

import { withDependencies } from '@wix/thunderbolt-ioc'
import { IAppPropsExtender } from '@wix/thunderbolt-symbols'
import { ComponentsLoaderSymbol } from './symbols'
import { IComponentsLoader } from './IComponentLoader'

const componentsLoaderInit = (componentsLoader: IComponentsLoader): IAppPropsExtender => {
	return {
		async extendAppProps() {
			return {
				comps: componentsLoader.getComponentsMap(),
				compControllers: componentsLoader.getCompControllersMap(),
			}
		},
	}
}

export const ComponentsLoaderInit = withDependencies([ComponentsLoaderSymbol], componentsLoaderInit)

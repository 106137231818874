import { ComponentEntry } from '../../core/common-types'

const entry: ComponentEntry = {
	componentType: 'tpaWidgetNative',
	loadComponent: () => {
		if (process.env.browser) {
			return import('./tpaWidgetNativeClient' /* webpackChunkName: "tpaWidgetNativeClient" */)
		} else {
			return import('./tpaWidgetNativeSSR')
		}
	},
}

export default entry

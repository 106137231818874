import * as pageInitializer from './pageInitializer/pageInitializer'
import * as thunderbolt from './thunderbolt/Thunderbolt'
import * as compEventsRegistrar from './compEventsRegistrar/compEventsRegistrar'
import * as compRefs from './compRefs/compRefs'
import * as appProps from './thunderbolt/AppPropsImpl'
import * as compsLifeCycle from './compsLifeCycle/compsLifeCycle'

import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'

const featureLoaders = [compEventsRegistrar, compRefs, appProps, pageInitializer, thunderbolt, compsLifeCycle]

export const site: ContainerModuleLoader = (bind, bindAll) =>
	featureLoaders.forEach(({ site: siteLoader }) => siteLoader(bind, bindAll))
